import React, { useState, useEffect } from "react";
import { Layout, Menu, Button } from "antd";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import AuthModal from "./components/Authmodal.tsx";
import "./App.scss";
import Albaranes from "./components/Albaranes.tsx";
import axios from "axios";

const { Header, Footer, Content } = Layout;

const App: React.FC = () => {
	const [odooStatus, setOdooStatus] = useState<string>(() => {
		const storedStatus = localStorage.getItem("odooStatus");
		return storedStatus ? storedStatus : "Desconectado";
	});
	const [response, setResponse] = useState<string>("");

	const [showAuthModal, setShowAuthModal] = useState<boolean>(false);

	// Lógica para abrir el modal de login
	const handleLogin = () => {
		setShowAuthModal(true);
	};

	// Lógica para cerrar sesión
	const handleLogout = async () => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/logout/`
			);
			console.log(response.data.message);
			setOdooStatus("Desconectado");
			setShowAuthModal(true);
		} catch (error) {
			console.error("Error al cerrar sesión:", error);
		}
	};

	// Efecto para almacenar el estado de Odoo en el localStorage y manejar el modal de autenticación
	useEffect(() => {
		localStorage.setItem("odooStatus", odooStatus);

		// Si Odoo está desconectado, se muestra el modal de autenticación
		if (odooStatus === "Desconectado") {
			setShowAuthModal(true);
		} else {
			setShowAuthModal(false);
		}
	}, [odooStatus]);

	return (
		<Router>
			<Layout style={{ minHeight: "100vh" }}>
				<AuthModal
					setResponse={setResponse}
					setOdooStatus={setOdooStatus}
					odooStatus={odooStatus}
					show={showAuthModal}
					setShowAuthModal={setShowAuthModal}
				/>
				<Header>
					<div className="logo">
						<div
							className={
								odooStatus === "Conectado" ? "conectado" : "desconectado"
							}>
							<p>{odooStatus}</p>
						</div>
						{odooStatus === "Conectado" ? (
							<Button onClick={handleLogout} style={{ marginLeft: "20px" }}>
								Cerrar sesión
							</Button>
						) : (
							<Button onClick={handleLogin} style={{ marginLeft: "20px" }}>
								Iniciar sesión
							</Button>
						)}
					</div>
					<Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]} />
				</Header>
				<Layout className="layout-albaranes">
					<Content className="layout-albaranes-grid">
						<Routes>
							<Route
								path="/albaranes"
								element={<Albaranes odooStatus={odooStatus} />}
							/>
							<Route path="/" element={<Navigate to="/albaranes" />} />
						</Routes>
					</Content>
					<Footer style={{ textAlign: "center" }}></Footer>
				</Layout>
			</Layout>
		</Router>
	);
};

export default App;
