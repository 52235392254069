import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Pagination, Modal, Spin, message } from "antd"; // Importamos message de Ant Design
import SignatureCanvas from "react-signature-canvas";
import { CheckCircleOutlined } from "@ant-design/icons"; // Importamos el icono
import axios from "axios"; // Para hacer la solicitud a Django
import "../sass/Albaranes.scss";

const { Search } = Input;

interface Albaran {
	id: number;
	name: string;
	partner: string | null;
	location: string | null;
	location_dest: string | null;
	scheduled_date: string;
	state: string;
	is_signed: boolean; // Nuevo campo para determinar si está firmado
}

// Agrega la prop para recibir odooStatus
interface AlbaranesProps {
	odooStatus: string;
}

const Albaranes: React.FC<AlbaranesProps> = ({ odooStatus }) => {
	const [albaranes, setAlbaranes] = useState<Albaran[]>([]);
	const [totalAlbaranes, setTotalAlbaranes] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [signing, setSigning] = useState<boolean>(false); // Estado para mostrar el loader cuando se está firmando
	const [searchText, setSearchText] = useState<string>("");
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [selectedAlbaran, setSelectedAlbaran] = useState<Albaran | null>(null); // Para abrir el modal de firma
	const [isModalVisible, setIsModalVisible] = useState(false); // Estado del modal de firma
	const signatureRef = useRef<SignatureCanvas | null>(null); // Referencia para la firma
	const signatureContainerRef = useRef<HTMLDivElement>(null); // Referencia para el contenedor de la firma
	const [debounceTimeout, setDebounceTimeout] = useState<number | null>(null); // Cambiamos a number | null
	const albaranesPerPage = 30;

	// Función para ajustar el tamaño del lienzo cuando el modal esté completamente renderizado
	const adjustSignatureCanvasSize = () => {
		if (signatureRef.current && signatureContainerRef.current) {
			const container = signatureContainerRef.current;
			const canvas = signatureRef.current.getCanvas();
			if (canvas) {
				signatureRef.current.clear();
				canvas.width = container.offsetWidth;
				canvas.height = container.offsetHeight;
			}
		}
	};

	useEffect(() => {
		// Ajustar el tamaño del lienzo cuando el modal se abre
		if (isModalVisible) {
			adjustSignatureCanvasSize();
		}
	}, [isModalVisible]);

	const filterAlbaranes = async (searchValue: string) => {
		setLoading(true); // Mostrar el loader
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/search_albaranes/`,
				{
					reference: searchValue, // Usar el valor actualizado de búsqueda
				}
			);
			const { albaranes, total } = response.data;
			const startIndex = (currentPage - 1) * albaranesPerPage;
			const endIndex = startIndex + albaranesPerPage;
			setAlbaranes(albaranes.slice(startIndex, endIndex));
			setTotalAlbaranes(total);
		} catch (error) {
			console.error("Error al buscar albaranes:", error);
		}
		setLoading(false); // Ocultar el loader
	};

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchText(value);
		setCurrentPage(1); // Reiniciar la página al cambiar el texto de búsqueda

		// Aplicar debounce para esperar antes de realizar la búsqueda
		if (debounceTimeout) {
			clearTimeout(debounceTimeout);
		}

		const newTimeout = window.setTimeout(() => {
			filterAlbaranes(value); // Pasar el valor actual de búsqueda a la función
		}, 700); // 700 ms de espera antes de ejecutar la búsqueda

		setDebounceTimeout(newTimeout);
	};

	const handleSearch = () => {
		if (debounceTimeout) {
			clearTimeout(debounceTimeout); // Cancelar el debounce en progreso
		}
		filterAlbaranes(searchText); // Ejecutar la búsqueda inmediatamente
	};

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
		filterAlbaranes(searchText); // Llamar a la función de búsqueda cuando se cambia de página
	};

	// Abrir modal para firmar albarán
	const handleAlbaranClick = (albaran: Albaran) => {
		setSelectedAlbaran(albaran);
		setIsModalVisible(true);

		// Limpiar la firma si hay una anterior y ajustar tamaño
		if (signatureRef.current) {
			signatureRef.current.clear(); // Limpiar la firma
		}

		setTimeout(() => {
			adjustSignatureCanvasSize(); // Ajustar tamaño tras abrir el modal
		}, 300); // Añadir un pequeño retardo para asegurar que el modal está completamente abierto
	};

	// Cerrar modal
	const handleCloseModal = () => {
		setIsModalVisible(false);
		setSelectedAlbaran(null); // Limpiar el albarán seleccionado

		// Resetear el canvas manualmente
		if (signatureRef.current) {
			signatureRef.current.clear(); // Limpiar la firma
		}
	};

	// Enviar firma
	const handleFirmar = async () => {
		setSigning(true); // Inicia el loader de firma
		if (signatureRef.current) {
			const signatureData = signatureRef.current
				.getTrimmedCanvas()
				.toDataURL("image/png");

			try {
				// Enviar la firma al backend
				const response = await axios.post(
					`${process.env.REACT_APP_BACKEND_URL}/firma_albaran/`,
					{
						albaran_id: selectedAlbaran?.id,
						signature: signatureData,
					}
				);

				// Actualizamos el estado de is_signed localmente
				setAlbaranes((prevAlbaranes) =>
					prevAlbaranes.map((albaran) =>
						albaran.id === selectedAlbaran?.id
							? { ...albaran, is_signed: true }
							: albaran
					)
				);

				message.success("Albarán firmado correctamente"); // Mostrar éxito
				handleCloseModal(); // Cerrar el modal después de firmar
			} catch (error) {
				message.error("Error al enviar la firma"); // Mostrar error
				console.error("Error al enviar la firma:", error);
			}
		}
		setSigning(false); // Finaliza el loader de firma
	};

	// Si Odoo está desconectado, no mostramos nada o mostramos un mensaje
	if (odooStatus === "Desconectado") {
		return (
			<div className="main-container-albaranes">
				<div className="albaranes-container">
					Por favor, conéctate a Odoo para ver los albaranes.
				</div>
			</div>
		);
	}

	return (
		<div className="main-container-albaranes">
			<div className="search-container">
				<h2>Buscar albarán para firmar</h2>
				<Search
					placeholder="Buscar albaranes por referencia"
					value={searchText}
					onChange={handleSearchChange}
					onSearch={handleSearch}
					disabled={odooStatus === "Desconectado"} // Deshabilita el input si está desconectado
					style={{ marginRight: "10px", width: "300px" }}
				/>
			</div>

			{/* Loading spinner cuando se están cargando los albaranes */}
			{loading ? (
				<Spin tip="Cargando albaranes..." />
			) : (
				!loading &&
				albaranes.length > 0 && (
					<div className="albaranes-container">
						{albaranes.map((albaran) => (
							<div
								key={albaran.id}
								className={`albaran ${albaran.is_signed ? "firmado" : ""}`}
								onClick={() => handleAlbaranClick(albaran)} // Al hacer clic, abrir el modal
							>
								<div className="header-sec">
									<h2 className="albaran-name">{albaran.name} </h2>
									{albaran.is_signed && (
										<CheckCircleOutlined
											style={{ color: "green", marginLeft: 8, right: 0 }}
										/>
									)}
								</div>
								<p>Cliente: {albaran.partner || "N/A"}</p>
								<p>Origen: {albaran.location || "N/A"}</p>
								<p>Destino: {albaran.location_dest || "N/A"}</p>
								<p>
									Fecha programada:{" "}
									{new Date(albaran.scheduled_date).toLocaleDateString()}
								</p>
								<p>Estado: {albaran.state}</p>
							</div>
						))}
						<Pagination
							current={currentPage}
							pageSize={albaranesPerPage}
							total={totalAlbaranes}
							onChange={handlePageChange}
							style={{ marginTop: "20px", textAlign: "center" }}
						/>
					</div>
				)
			)}

			{/* Modal para firma a pantalla completa */}
			<Modal
				title={`Firma de Albarán: ${selectedAlbaran?.name}`}
				open={isModalVisible}
				onCancel={handleCloseModal}
				footer={null} // Sin footer para modal de firma
				width="100vw" // Ancho completo
				style={{ top: 0, padding: 0 }} // Estilos para pantalla completa
			>
				<Spin spinning={signing} tip="Firmando albarán...">
					<div className="firma-container" ref={signatureContainerRef}>
						<SignatureCanvas
							ref={signatureRef}
							canvasProps={{ className: "signature-canvas" }}
						/>
					</div>

					<div className="container-buttons">
						<Button
							className="firmar-button"
							type="primary"
							onClick={handleFirmar}
							style={{ marginTop: "20px" }}>
							Firmar
						</Button>
					</div>
				</Spin>
			</Modal>
		</div>
	);
};

export default Albaranes;
