// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
  height: 100%;
}
.logo .conectado p,
.logo .desconectado p {
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
  height: auto;
  font-weight: 500;
}
.logo .conectado p {
  color: rgb(83, 223, 118);
}
.logo .desconectado p {
  color: rgb(255, 78, 78);
}

.connection-status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.connection-status .status-text {
  margin-left: 1rem !important;
  margin: 0;
}
.connection-status .status-text-conectado {
  color: rgb(0, 117, 18);
  background-color: rgb(175, 249, 175);
  padding: 0px 20px;
  border-radius: 15px;
}
.connection-status .status-text-desconectado {
  color: rgb(187, 1, 1);
  background-color: rgb(254, 157, 157);
  padding: 0px 20px;
  border-radius: 15px;
}

.layout-albaranes-grid + div {
  width: 25%;
  position: fixed;
  right: 0;
  top: 64px; /* Altura del header */
  bottom: 0;
  overflow-y: auto;
  background-color: #fff;
  border-left: 1px solid #f0f0f0;
  padding: 24px;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;AACD;AAEE;;EACC,qBAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;AACH;AAGC;EACC,wBAAA;AADF;AAIC;EACC,uBAAA;AAFF;;AAMA;EACC,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;AAHD;AAKC;EACC,4BAAA;EACA,SAAA;AAHF;AAMC;EACC,sBAAA;EACA,oCAAA;EACA,iBAAA;EACA,mBAAA;AAJF;AAOC;EACC,qBAAA;EACA,oCAAA;EACA,iBAAA;EACA,mBAAA;AALF;;AASA;EACC,UAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA,EAAA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,sBAAA;EACA,8BAAA;EACA,aAAA;AAND","sourcesContent":[".logo {\n\tdisplay: flex;\n\talign-items: center;\n\twhite-space: nowrap;\n\tjustify-content: space-between;\n\theight: 100%;\n\t.conectado,\n\t.desconectado {\n\t\tp {\n\t\t\tdisplay: inline-block; // Para que el párrafo solo ocupe el espacio del texto\n\t\t\tpadding: 3px 5px; // Reducir el espacio alrededor del texto\n\t\t\tborder-radius: 5px; // Redondear bordes, si lo deseas\n\t\t\theight: auto; // Elimina la restricción de altura\n\t\t\tfont-weight: 500;\n\t\t}\n\t}\n\n\t.conectado p {\n\t\tcolor: rgb(83, 223, 118);\n\t}\n\n\t.desconectado p {\n\t\tcolor: rgb(255, 78, 78);\n\t}\n}\n\n.connection-status {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: flex-start;\n\tmargin-bottom: 1rem;\n\n\t.status-text {\n\t\tmargin-left: 1rem !important;\n\t\tmargin: 0;\n\t}\n\n\t.status-text-conectado {\n\t\tcolor: rgb(0, 117, 18);\n\t\tbackground-color: rgb(175, 249, 175);\n\t\tpadding: 0px 20px;\n\t\tborder-radius: 15px;\n\t}\n\n\t.status-text-desconectado {\n\t\tcolor: rgb(187, 1, 1);\n\t\tbackground-color: rgb(254, 157, 157);\n\t\tpadding: 0px 20px;\n\t\tborder-radius: 15px;\n\t}\n}\n\n.layout-albaranes-grid + div {\n\twidth: 25%;\n\tposition: fixed;\n\tright: 0;\n\ttop: 64px; /* Altura del header */\n\tbottom: 0;\n\toverflow-y: auto;\n\tbackground-color: #fff;\n\tborder-left: 1px solid #f0f0f0;\n\tpadding: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
