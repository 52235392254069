import React, { useState } from "react";
import { Modal, Button, Input, message, Spin } from "antd";
import axios from "axios";

interface AuthModalProps {
	setResponse: React.Dispatch<React.SetStateAction<string>>;
	setOdooStatus: React.Dispatch<React.SetStateAction<string>>;
	odooStatus: string;
	show: boolean;
	setShowAuthModal: React.Dispatch<React.SetStateAction<boolean>>; // Añadimos esta prop para controlar la visibilidad
}

const AuthModal: React.FC<AuthModalProps> = ({
	setResponse,
	setOdooStatus,
	odooStatus,
	show,
	setShowAuthModal, // Recibimos esta prop para controlar el modal desde el componente padre
}) => {
	// Estados para el usuario y la contraseña
	const [username, setUsername] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false); // Estado para manejar el loading

	// Función para manejar la conexión a Odoo
	const handleConnectToOdoo = async () => {
		// Verificamos si el usuario y la contraseña son correctos
		if (username === "admin" && password === "admin") {
			setLoading(true); // Activar el loading
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_BACKEND_URL}/connect-odoo/`
				);
				console.log(response);
				setResponse(response.data.message);
				setOdooStatus("Conectado");
				// Cierra el modal una vez que se haya conectado exitosamente
				setShowAuthModal(false);
				message.success("Conexión a Odoo exitosa");
			} catch (error) {
				setResponse("Error al conectar a Odoo");
				message.error("Error al conectar a Odoo");
			} finally {
				setLoading(false); // Desactivar el loading
			}
		} else {
			// Si las credenciales no son correctas, mostramos un error
			message.error("Usuario o contraseña incorrectos");
		}
	};

	return (
		<Modal
			title="Login a Odoo"
			open={show} // Usamos 'open' en lugar de 'visible'
			footer={[
				<Button
					key="connect"
					type="primary"
					onClick={handleConnectToOdoo}
					disabled={loading} // Desactivar el botón mientras está cargando
				>
					{loading ? <Spin /> : "Conectar"}{" "}
					{/* Mostrar spinner si está cargando */}
				</Button>,
			]}
			onCancel={() => setShowAuthModal(false)} // Cierra el modal si se hace clic en el botón Cancelar o fuera del modal
		>
			<Spin spinning={loading} tip="Conectando...">
				{" "}
				{/* Spinner mientras se conecta */}
				<p>Por favor, introduce tus credenciales para conectarte a Odoo.</p>
				<Input
					placeholder="Usuario"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
					style={{ marginBottom: 10 }}
					disabled={loading} // Desactivar input mientras carga
				/>
				<Input.Password
					placeholder="Contraseña"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					disabled={loading} // Desactivar input mientras carga
				/>
			</Spin>
		</Modal>
	);
};

export default AuthModal;
